<template>
  <div class="outpatient_container">
    <div class="mask" v-if="notice"></div>
    <div class="popup" v-if="notice">
      <h1>门诊缴费注意事项</h1>
      <p>1.自助缴费暂只支持自费，如需医保报销请到人工收费窗口缴费！</p>
      <p>2.自助缴费成功后如需退检查费、检验费、退药费均到人工收费窗口办理</p>
      <el-button type="primary" @click.native="ok">我知道了</el-button>
    </div>
    <el-row class="outpatient_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <el-row class="outpatient_tip">
      <h1>温馨提示：自助缴费请确认收费信息后，点击下方确认支付按钮</h1>
    </el-row>
    <div class="outpatient_main">
      <el-table :data="tableData" v-loading="loading" border="" height="70%" style="width: 100%">
        <el-table-column label="序号" prop="XH"></el-table-column>
        <el-table-column label="处方医生" prop="CFYS"></el-table-column>
        <el-table-column label="项目名称" prop="MC"></el-table-column>
        <el-table-column label="项目数量" prop="YL"></el-table-column>
        <el-table-column label="项目单位" prop="DW"></el-table-column>
        <el-table-column label="项目合计金额（元）" prop="JE"></el-table-column>
      </el-table>
    </div>
    <el-button type="primary" class="home" @click.native="home">首页</el-button>
    <el-button type="danger" class="pay" @click.native="pay">确定支付</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { jsonPost, formPost } from '@/baseAPI'
import { getOutPatientFeeDetail, outPatientPreSet } from '@/service/outpatient'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '门诊缴费',
      notice: false,
      tableData: [],
      query: {},
      orderNum: '',
      totalAmout: '',
      loading: false,
      deptName: '',
      doctorName: '',
      diagnTime: ''
    }
  },
  created() {
    this.query = this.$route.query
    this.getClinicFeeDetail()
  },
  methods: {
    async getClinicFeeDetail() {
      this.loading = true
      let data = {
        SFID: this.$route.query.SFID,
        CFRQ: this.$route.query.CFRQ
      }
      const res = await getOutPatientFeeDetail(data)
      console.log(res)

      if (res.data.code == 0) {
        this.loading = false
        if (Array.isArray(res.data.data.DATASET.ROW)) {
          this.tableData = res.data.data.DATASET.ROW
        } else {
          let item = []
          item.push(res.data.data.DATASET.ROW)
          this.tableData = item
        }
        this.totalAmout = res.data.totalAmout
        this.deptName = res.data.deptName
        this.doctorName = res.data.doctorName
        this.diagnTime = res.data.diagnTime
      } else {
        this.loading = false
        this.$message({
          message: data[0].msg,
          type: 'error'
        })
      }
    },

    ok() {
      this.notice = false
    },
    home() {
      this.$router.push('/home')
    },
    async pay() {
      this.$router.push({
        path: '/outPaTiePay',
        query: {
          productId: this.$route.query.SFID,
          cfrq: this.$route.query.CFRQ,
          xm: localStorage.getItem('name'),
          totalFee: this.$route.query.JE
        }
      })
    }
    // this.$router.push({
    //   path: '/qsCodePay',
    //   query: params
    // })
  }
}
</script>
<style>
.outpatient_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.outpatient_container .mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.1;
  z-index: 1;
}

.outpatient_container .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcdfe6;
  width: 70%;
  height: 60%;
  border-radius: 10px;
  padding: 20px 30px;
  z-index: 999;
}

.outpatient_container .popup h1 {
  text-align: center;
  letter-spacing: 10px;
  font-size: 32px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.outpatient_container .popup p {
  margin-bottom: 20px;
  color: red;
  font-size: 28px;
  line-height: 40px;
}

.outpatient_container .popup .el-button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 28px !important;
}

.outpatient_main {
  width: 90%;
  height: 70%;
  margin: 0 auto;
}

.outpatient_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.outpatient_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
}

.outpatient_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.outpatient_tip h1 {
  margin: 30px 0;
  font-weight: 400;
  color: red;
  text-align: center;
  font-size: 34px;
}

.outpatient_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.outpatient_info .left {
  float: left;
  width: 50%;
}

.outpatient_info .right {
  float: right;
  width: 50%;
}

.outpatient_info p {
  padding-left: 30%;
  font-size: 26px;
  color: #458ce9;
  margin-bottom: 20px;
}

.outpatient_container .home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}

.outpatient_container .pay {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 300px;
  font-size: 26px;
}
</style>
